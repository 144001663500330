import { Popover } from "@headlessui/react";
import Logo from "../assets/logoNameWoBackground.svg";
export default function Privacy() {
  return (
    <div className="bg-white">
      <header>
        <Popover className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <span className="sr-only">Bolo</span>
                <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
              </a>
            </div>
          </div>
        </Popover>
      </header>
      <div>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-12">
            <h1 className="text-4xl font-bold pb-10">PRIVACY POLICY</h1>
            <p className="text-left py-2">Last Updated: January 2022</p>
            <p className="text-left py-2">
              This website privacy policy describes how Bolo.fm protects and
              makes use of the information you give the company when you use
              this website.
            </p>
            <p className="text-left py-2">
              If you are asked to provide information when using this website,
              it will only be used in the ways described in this privacy policy.
            </p>
            <p className="text-left py-2">
              This policy is updated from time to time. The latest version is
              published on this page.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Subscriptions</h3>
            <p className="text-left py-2">
              If you have any questions about this policy, please email contact
              us.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Introduction</h3>
            <p className="text-left py-2">
              We gather and use certain information about individuals in order
              to provide products and services and to enable certain functions
              on this website.
            </p>
            <p className="text-left py-2">
              We also collect information to better understand how visitors use
              this website and to present timely, relevant information to them.{" "}
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">
              What data we gather
            </h3>
            <p className="text-left py-2">
              Bolo.fm is used in two different ways. The first is by account
              owners (Account Owners) and the second is the people who send
              messages (End Users) to the account owners.{" "}
            </p>
            <p className="text-left py-2">
              For Account Owners, we may collect the following information:
            </p>
            <ul>
              <li className="text-left list-disc ml-5">Name</li>
              <li className="text-left list-disc ml-5">
                Contact information including email and address
              </li>
              <li className="text-left list-disc ml-5">Website usage data</li>
              <li className="text-left list-disc ml-5">
                Other information volunteered by the Account Owner
              </li>
            </ul>
            <p className="text-left py-2">
              For End Users, we may collect the following information:
            </p>
            <ul>
              <li className="text-left list-disc ml-5">Name</li>
              <li className="text-left list-disc ml-5">
                Contact information including email and address
              </li>
              <li className="text-left list-disc ml-5">Website usage data</li>
              <li className="text-left list-disc ml-5">Messages submitted</li>
              <li className="text-left list-disc ml-5">
                Other information volunteered by the Account Owner
              </li>
            </ul>
            <p className="text-left py-2">
              Information submitted by End Users is only accessable by us
              (Bolo.fm) and the Account Onwer the information is intended for.
              Our policy is that we (Bolo.fm) do not access these messages
              unless required for diagnostic purposes while solving a technical
              issue.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">
              How we use this data
            </h3>
            <p className="text-left py-2">
              Collecting this data helps us understand what you are looking from
              the company, enabling us to deliver improved products and
              services.
            </p>
            <p className="text-left py-2">We may use this information to:</p>
            <ul>
              <li className="text-left list-disc ml-5">
                For our own internal records
              </li>
              <li className="text-left list-disc ml-5">
                To improve the products and services we provide
              </li>
              <li className="text-left list-disc ml-5">
                To contact you in response to a specific enquiry
              </li>
              <li className="text-left list-disc ml-5">
                To customise the website for you
              </li>
              <li className="text-left list-disc ml-5">
                To send you promotional emails about products, services, offers
                and other things we think might be relevant to you.
              </li>
              <li className="text-left list-disc ml-5">
                To contact you via email for market research reasons.
              </li>
            </ul>
            <h3 className="text-left text-2xl py-3 font-bold">
              How we use cookies
            </h3>
            <p className="text-left py-2">
              We use cookies to help us understand how you use our website.
              Cookies are small files placed on your computer to collect
              information about how you use our website. This helps us to
              understand how we can improve our website for you.
            </p>
            <p className="text-left py-2">We may use cookies to:</p>
            <ul>
              <li className="text-left list-disc ml-5">
                Analyse our web traffic using an analytics package. Aggregated
                usage data helps us improve the website structure, design,
                content and functions.
              </li>
              <li className="text-left list-disc ml-5">
                Identify whether you are signed in to our website. A cookie
                allows us to check whether you are signed in to the site.
              </li>
              <li className="text-left list-disc ml-5">
                Test content on our website.
              </li>
              <li className="text-left list-disc ml-5">
                Store information about your preferences. The website can then
                present you with information you will find more relevant and
                interesting.
              </li>
              <li className="text-left list-disc ml-5">
                To recognise when you return to our website. We may show your
                relevant content, or provide functionality you used previously.
              </li>
              <li className="text-left list-disc ml-5">
                Use retargeting ads on other platforms such as
                Facebook/Twitter/Google/LinkedIn{" "}
              </li>
            </ul>
            <p className="text-left py-2">
              Cookies do not provide us with access to your computer or any
              information about you, other than that which you choose to share
              with us.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">
              Controlling Cookies
            </h3>
            <p className="text-left py-2">
              You can use your web browser’s cookie settings to determine how
              our website uses cookies. If you do not want our website to store
              cookies on your computer or device, you should set your web
              browser to refuse cookies.
            </p>
            <p className="text-left py-2">
              However, please note that doing this may affect how our website
              functions. Some pages and services may become unavailable to you.
            </p>
            <p className="text-left py-2">
              Unless you have changed your browser to refuse cookies, our
              website will issue cookies when you visit it.
            </p>
            <p className="text-left py-2">
              To learn more about cookies and how they are used, visit All About
              Cookies.
            </p>
            <p className="text-left py-2">
              We will never lease, distribute or sell your personal information
              to third parties unless we have your permission or the law
              requires us to.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Security</h3>
            <p className="text-left py-2">
              We will always hold your information securely.
            </p>
            <p className="text-left py-2">
              To prevent unauthorised disclosure or access to your information,
              we have implemented strong electronic security safeguards.{" "}
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">
              Links from our site
            </h3>
            <p className="text-left py-2">
              Our website may contain links to other websites.
            </p>
            <p className="text-left py-2">
              Please note that we have no control of websites outside the
              bolo.fm domain. If you provide information to a website to which
              we link, we are not responsible for its protection and privacy.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
