// import logo from './logo.svg';
import "./App.css";
// import "tailwindcss/tailwind.css"
import SignUpPage from "./pages/signup";
import LoginPage from "./pages/login";
import Dashboard from "./pages/dashboard";
// import Recorder from "./pages/recorder";
import RecorderV2 from "./pages/recorder_v2";
import NotFoundPage from "./pages/404";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/auth";
import Landing from "./pages/landing";
import AudioClipsList from "./components/inbox";
import Settings from "./components/settings";
import TOS from "./pages/tos";
import Privacy from "./pages/privacy";
import { Helmet } from "react-helmet";


function App() {
  return (
    <>
    <Helmet>
      <title>Bolo.fm</title>
      <meta name="description" content="Share the voice of your top fans and customers!" />
    </Helmet>
    <Router>
      <div className="App antialiased">
        <AuthProvider>
          <Routes>
            <Route path="">
              <Landing />
            </Route>
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="/legal/tos" element={<TOS />} />
            <Route path="/legal/privacy" element={<Privacy />} />
            <Route
              path="/inbox"
              element={<Dashboard element={<AudioClipsList />} />}
            />
            <Route
              path="/settings"
              element={<Dashboard element={<Settings />} />}
            />
            <Route path="/:username" element={<RecorderV2 />} />
          </Routes>
        </AuthProvider>
      </div>
    </Router>
    </>
  );
}

export default App;
