import Avatar from "boring-avatars";

export default function BoloAvatar(props) {
  return (
    <Avatar
      size={"100%"}
      name={props.name}
      variant="beam"
      colors={["#ede90e", "#ed370e", "#F0AB3D", "#C271B4", "#000000"]}
      square={false}
    />
  );
}
