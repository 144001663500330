import { Popover } from "@headlessui/react";
import Logo from "../assets/logoNameWoBackground.svg";
export default function TOS() {
  return (
    <div className="bg-white">
      <header>
        <Popover className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <span className="sr-only">Bolo</span>
                <img className="h-8 w-auto sm:h-10" src={Logo} alt="" />
              </a>
            </div>
          </div>
        </Popover>
      </header>
      <div>
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-12">
            <h1 className="text-4xl font-bold pb-10">TERMS OF SERVICE</h1>
            <p className="text-left py-2">Last Updated: January 2022</p>
            <p className="text-left py-2">
              Please read these Terms and Conditions ("Terms", "Terms and
              Conditions") carefully before using the https://bolo.fm website
              (the "Service") operated by Bolo.fm ("us", "we", or "our").
            </p>
            <p className="text-left py-2">
              Your access to and use of the Service is conditioned on your
              acceptance of and compliance with these Terms. These Terms apply
              to all visitors, users and others who access or use the Service.
            </p>
            <p className="text-left py-2">
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms then you may not
              access the Service.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Subscriptions</h3>
            <p className="text-left py-2">
              Some parts of the Service are billed on a subscription basis
              ("Subscription(s)"). You will be billed in advance on a recurring
              and periodic basis ("Billing Cycle"). Billing cycles are set on a
              yearly basis.
            </p>
            <p className="text-left py-2">
              At the end of each Billing Cycle, your Subscription will
              automatically renew under the exact same conditions unless you
              cancel it or Bolo.fm cancels it. You may cancel your Subscription
              renewal by contacting Bolo.fm customer support team or directly
              from your dashboard.
            </p>
            <p className="text-left py-2">
              A valid payment method, including credit card, is required to
              process the payment for your Subscription. You shall provide
              Bolo.fm with accurate and complete billing information including
              full name, address, zip code, and a valid payment method
              information. By submitting such payment information, you
              automatically authorize Bolo.fm to charge all Subscription fees
              incurred through your account to any such payment instruments.
            </p>
            <p className="text-left py-2">
              Should automatic billing fail to occur for any reason, Bolo.fm
              will issue an electronic invoice indicating that you must proceed
              manually, within a certain deadline date, with the full payment
              corresponding to the billing period as indicated on the invoice.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Fee Changes</h3>
            <p className="text-left py-2">
              Bolo.fm, in its sole discretion and at any time, may modify the
              Subscription fees for the Subscriptions. Any Subscription fee
              change will become effective at the end of the then-current
              Billing Cycle.{" "}
            </p>
            <p className="text-left py-2">
              Bolo.fm will provide you with a reasonable prior notice of any
              change in Subscription fees to give you an opportunity to
              terminate your Subscription before such change becomes effective.
            </p>
            <p className="text-left py-2">
              Your continued use of the Service after the Subscription fee
              change comes into effect constitutes your agreement to pay the
              modified Subscription fee amount.{" "}
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Refunds</h3>
            <p className="text-left py-2">
              All requests for refunds that we recieve within 24 hours of the
              purchase time will be accepted. Certain refund requests for
              Subscriptions after this time window may be considered by Bolo.fm
              on a case-by-case basis and granted in sole discretion of Bolo.fm.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Accounts</h3>
            <p className="text-left py-2">
              When you create an account with us, you must provide us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of your account on our Service.
            </p>
            <p className="text-left py-2">
              You are responsible for safeguarding the password that you use to
              access the Service and for any activities or actions under your
              password, whether your password is with our Service or a
              third-party service.{" "}
            </p>
            <p className="text-left py-2">
              You agree not to disclose your password to any third party. You
              must notify us immediately upon becoming aware of any breach of
              security or unauthorized use of your account.{" "}
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">
              Links To Other Web Sites
            </h3>
            <p className="text-left py-2">
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by us.
            </p>
            <p className="text-left py-2">
              Bolo.fm has no control over, and assumes no responsibility for,
              the content, privacy policies, or practices of any third party web
              sites or services. You further acknowledge and agree that Bolo.fm
              shall not be responsible or liable, directly or indirectly, for
              any damage or loss caused or alleged to be caused by or in
              connection with use of or reliance on any such content, goods or
              services available on or through any such web sites or services.{" "}
            </p>
            <p className="text-left py-2">
              We strongly advise you to read the terms and conditions and
              privacy policies of any third-party web sites or services that you
              visit.{" "}
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Termination</h3>
            <p className="text-left py-2">
              We may terminate or suspend your account immediately, without
              prior notice or liability, for any reason whatsoever, including
              without limitation if you breach the Terms.
            </p>
            <p className="text-left py-2">
              Upon termination, your right to use the Service will immediately
              cease. If you wish to terminate your account, you may simply
              discontinue using the Service.
            </p>
            <p className="text-left py-2">
              All provisions of the Terms which by their nature should survive
              termination shall survive termination, including, without
              limitation, ownership provisions, warranty disclaimers, indemnity
              and limitations of liability.{" "}
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">
              Indemnification
            </h3>
            <p className="text-left py-2">
              You agree to defend, indemnify and hold harmless Bolo.fm and its
              licensee and licensors, and their employees, contractors, agents,
              officers and directors, from and against any and all claims,
              damages, obligations, losses, liabilities, costs or debt, and
              expenses (including but not limited to attorney's fees), resulting
              from or arising out of a) your use and access of the Service, by
              you or any person using your account and password; b) a breach of
              these Terms, or c) Content posted on the Service.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">
              Limitation Of Liability
            </h3>
            <p className="text-left py-2">
              In no event shall Bolo.fm, nor its directors, employees, partners,
              agents, suppliers, or affiliates, be liable for any indirect,
              incidental, special, consequential or punitive damages, including
              without limitation, loss of profits, data, use, goodwill, or other
              intangible losses, resulting from (i) your access to or use of or
              inability to access or use the Service; (ii) any conduct or
              content of any third party on the Service; (iii) any content
              obtained from the Service; and (iv) unauthorized access, use or
              alteration of your transmissions or content, whether based on
              warranty, contract, tort (including negligence) or any other legal
              theory, whether or not we have been informed of the possibility of
              such damage, and even if a remedy set forth herein is found to
              have failed of its essential purpose.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Disclaimer</h3>
            <p className="text-left py-2">
              Your use of the Service is at your sole risk. The Service is
              provided on an "AS IS" and "AS AVAILABLE" basis. The Service is
              provided without warranties of any kind, whether express or
              implied, including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose,
              non-infringement or course of performance.
            </p>
            <p className="text-left py-2">
              Bolo.fm its subsidiaries, affiliates, and its licensors do not
              warrant that a) the Service will function uninterrupted, secure or
              available at any particular time or location; b) any errors or
              defects will be corrected; c) the Service is free of viruses or
              other harmful components; or d) the results of using the Service
              will meet your requirements.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Exclusions</h3>
            <p className="text-left py-2">
              Without limiting the generality of the foregoing and
              notwithstanding any other provision of these terms, under no
              circumstances will Bolo.fm ever be liable to you or any other
              person for any indirect, incidental, consequential, special,
              punitive or exemplary loss or damage arising from, connected with,
              or relating to your use of the Service, these Terms, the subject
              matter of these Terms, the termination of these Terms or
              otherwise, including but not limited to personal injury, loss of
              data, business, markets, savings, income, profits, use,
              production, reputation or goodwill, anticipated or otherwise, or
              economic loss, under any theory of liability (whether in contract,
              tort, strict liability or any other theory or law or equity),
              regardless of any negligence or other fault or wrongdoing
              (including without limitation gross negligence and fundamental
              breach) by Bolo.fm or any person for whom Bolo.fm is responsible,
              and even if Bolo.fm has been advised of the possibility of such
              loss or damage being incurred.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Governing Law</h3>
            <p className="text-left py-2">
              These Terms shall be governed and construed in accordance with the
              laws of Canada, without regard to its conflict of law provisions.
            </p>
            <p className="text-left py-2">
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service, and supersede and replace any prior agreements we might
              have between us regarding the Service.
            </p>
            <h3 className="text-left text-2xl py-3 font-bold">Changes</h3>
            <p className="text-left py-2">
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will try to
              provide at least 30 days notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </p>
            <p className="text-left py-2">
              By continuing to access or use our Service after those revisions
              become effective, you agree to be bound by the revised terms. If
              you do not agree to the new terms, you must stop using the
              service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
