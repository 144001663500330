/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useContext, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  MenuIcon,
  XIcon,
  LogoutIcon,
  CogIcon,
  InboxIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import Avatar from "../assets/avatar";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../contexts/auth";
import logoName from "../assets/logoName.svg";
import classNames from "../utils/classNames";
import AudioClipsList from "../components/inbox";
import Settings from "../components/settings";

export default function Dashboard(props) {
  const navigation = [
    {
      name: "Inbox",
      handleClick: () => {
        setCurrent("Inbox");
        setSidebarOpen(false);
      },
      icon: InboxIcon,
      current: false,
      linkTo: "/inbox",
    },
    {
      name: "Settings",
      handleClick: () => {
        setCurrent("Settings");
        setSidebarOpen(false);
      },
      icon: CogIcon,
      current: false,
      linkTo: "/settings",
    },
    {
      name: "Logout",
      handleClick: handleLogoutClick,
      icon: LogoutIcon,
      current: false,
      linkTo: "/",
    },
  ];

  const nav = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const _current = navigation.filter(
    (e) => e.linkTo === window.location.pathname
  )[0];
  const [current, setCurrent] = useState(_current.name);
  const { authContextFullName, authContextUsername, authContextAvatar } =
    useContext(AuthContext);
  const [audioClips, setAudioClips] = useState([]);
  const navigate = useNavigate();

  function handleUnassignedClick(e) {
    e.preventDefault();
  }
  async function fetchAudioClipsData() {
    // should send back login status and oboarding status
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/audio-clips`,
      {
        credentials: "include",
      }
    );
    const resClips = await res.json();
    setAudioClips(resClips);
    console.log(resClips);
    return res;
  }

  useEffect(() => {
    fetchAudioClipsData();
  }, [setAudioClips]);

  async function handleAudioClipDelete(clip_id) {
    console.log("getting here");
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/audio-clips/${clip_id}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    if (res.ok) {
      const _clips = audioClips.filter((clip) => clip.clip_id !== clip_id);
      setAudioClips(_clips);
    }
  }

  async function handleRefreshClick() {
    const res = await fetchAudioClipsData();
    if (res.status === 401) {
      navigate("/login");
    }
  }

  async function handleLogoutClick(e) {
    e.preventDefault();
    localStorage.clear();
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/logout`, {
      credentials: "include",
    });
    nav("/login");
  }

  useEffect(() => {
    async function verify() {
      try {
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/verify`, {
          credentials: "include",
        });
        if (!res.ok) {
          nav("/login");
        } else {
        }
      } catch (error) {
        console.log(error);
        nav("/login");
      }
    }
    verify();
  }, [nav]);

  return (
    <div className="h-screen flex overflow-hidden">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-bolo-purple">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img className="h-8 w-auto" src={logoName} alt="Bolo" />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link to={item.linkTo}>
                      <button
                        key={item.name}
                        onClick={item.handleClick}
                        className={classNames(
                          item.current
                            ? "text-white"
                            : "text-gray-300 hover:bg-gray-50 hover:text-black",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-white stroke-current"
                              : "group-hover:text-gray-500 stroke-current",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </button>
                    </Link>
                  ))}
                </nav>
              </div>
              <div className="my-2 mx-9 items-center justify-center">
                <div className="flex items-center justify-center pb-4 text-white">
                  <p>Check out your Bolo page</p>
                </div>
                <CopyButton
                  className="flex items-center justify-center"
                  toCopyText={`https://bolo.fm/${authContextUsername}`}
                />
              </div>
              <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                <button
                  onClick={handleUnassignedClick}
                  className="flex-shrink-0 group block"
                >
                  <div className="flex items-center">
                    <div className="inline-block h-10 w-10 rounded-full overflow-hidden">
                      {authContextAvatar ? (
                        <img src={authContextAvatar} alt="Avatar" />
                      ) : (
                        <Avatar name={authContextFullName || ""} />
                      )}
                    </div>
                    {/* <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div> */}
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-200 group-hover:text-white">
                        {authContextFullName || ""}
                      </p>
                      {/* <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                        View profile
                      </p> */}
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-bolo-purple">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img className="h-8 w-auto" src={logoName} alt="Bolo" />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-bolo-purple space-y-1">
                {navigation.map((item) => (
                  <Link to={item.linkTo}>
                    <button
                      key={item.name}
                      onClick={item.handleClick}
                      className={classNames(
                        item.current
                          ? "text-white"
                          : "text-gray-200 hover:text-white",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? "text-white stroke-current"
                            : "text-gray-200 group-hover:text-white",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </button>
                  </Link>
                ))}
              </nav>
              <div className="flex items-center justify-center text-white text-center pb-4">
                <p className="text-center">Check out your Bolo page</p>
              </div>
              <CopyButton
                toCopyText={`https://bolo.fm/${authContextUsername}`}
              />
            </div>
            <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
              <button
                onClick={handleUnassignedClick}
                className="flex-shrink-0 w-full group block"
              >
                <div className="flex items-center">
                  <div className="inline-block h-9 w-9 rounded-full overflow-hidden">
                    {authContextAvatar ? (
                      <img src={authContextAvatar} alt="Avatar" />
                    ) : (
                      <Avatar name={authContextFullName || ""} />
                    )}
                  </div>
                  {/* <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div> */}
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-200 group-hover:text-white">
                      {authContextFullName || ""}
                    </p>
                    {/* <p className="text-xs font-medium text-white group-hover:text-gray-700">
                      View profile
                    </p> */}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none bg-gray-50">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 py-4 grid grid-cols-2">
              <h1 className="text-2xl font-semibold text-gray-900 px-2 py-2 text-left">
                {current}
              </h1>
              {current === "Inbox" && (
                <div className="flex justify-end">
                  <button
                    key="refreshButton"
                    onClick={handleRefreshClick}
                    className="text-gray-600 hover:text-black group flex items-center px-2 py-2 text-sm font-medium rounded-md active:shadow-sm"
                  >
                    <RefreshIcon
                      className="text-gray-600 group-hover:text-black mr-3 flex-shrink-0 h-6 w-6"
                      aria-hidden="true"
                    />
                    Refresh
                  </button>
                </div>
              )}
            </div>
            <div className="relative" id="divider">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              {/* Display main content */}
              {current === "Inbox" && (
                <AudioClipsList
                  audioClips={audioClips}
                  handleAudioClipDelete={handleAudioClipDelete}
                  username={authContextUsername}
                />
              )}
              {current === "Settings" && <Settings setCurrent={setCurrent} />}
              {/* {props.element} */}
              {/* <Routes>
                <Route path="/" element={<AudioClipsList />} />
                <Route path="/inbox" element={<AudioClipsList />} />
                <Route path="/settings" element={<Settings />} />
              </Routes> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

function ToCopyButton(props) {
  return (
    <button
      type="button"
      className="inline-flex items-center justify-center w-3/4 py-3 border-2 border-white shadow-sm text text-base font-medium rounded-md text-white bg-bolo-purple hover:bg-bolo-purple-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bolo-purple-dark"
      onClick={props.handleClick}
    >
      {props.text}
      {props.icon && <span className="px-1">{props.icon}</span>}
    </button>
  );
}

function CopiedButton(props) {
  return (
    <button
      type="button"
      className="inline-flex items-center justify-center w-3/4 py-3 border-2 border-gray-500 shadow-sm text text-base font-medium rounded-md text-bolo-purple bg-white hover:bg-gray-200 focus:outline-none"
      onClick={props.handleClick}
    >
      {props.text}
      {props.icon && <span className="px-1">{props.icon}</span>}
    </button>
  );
}

function CopyButton(props) {
  const [copiedLink, setCopiedLink] = useState(false);

  function handleCopyClick(e) {
    navigator.clipboard.writeText(props.toCopyText);
    setCopiedLink(true);
    setTimeout(() => {
      setCopiedLink(false);
    }, 2000);
  }

  return (
    <Fragment>
      {!copiedLink ? (
        <div className="flex items-center justify-center">
          <ToCopyButton
            text={props.toCopyText}
            handleClick={handleCopyClick}
            // icon={<ClipboardCopyIcon className="h-6 w-6" />}
          />
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <CopiedButton
            text="Copied!"
            handleClick={props.handleUnassignedClick}
            // icon={<ClipboardCopyIcon className="h-6 w-6" />}
          />
        </div>
      )}
    </Fragment>
  );
}
