import { useState, useContext, useEffect, useRef } from "react";
// import { useNavigate } from "react-router";
import AuthContext from "../contexts/auth";
import classNames from "../utils/classNames";
// import Avatar from "../assets/avatar";

export default function Settings(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [requireEmail, setRequireEmail] = useState(true);
  const [requireName, setRequireName] = useState(true);
  const [usernameErr, setUsernameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [saved, setSaved] = useState(false);
  const [removed, setRemoved] = useState(false);
  const [, setAvatar] = useState(false);
  const [maxRecordingTime, setMaxRecordingTime] = useState(null);

  const backgroundImageInputRef = useRef();

  const { setAuthContextUsername } = useContext(AuthContext);
  const { setAuthContextEmail } = useContext(AuthContext);
  const { setAuthContextFullName } = useContext(AuthContext);
  const { setAuthContextAvatar } = useContext(AuthContext);

  // const navigator = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/settings`, {
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
      });
      if (res.status === 200) {
        const body = await res.json();
        setTitle(body.title);
        setDescription(body.description);
        setRequireEmail(body.poster_email_required);
        setRequireName(body.poster_name_required);
        setUsername(body.username);
        setFullName(body.full_name);
        setEmail(body.email);
        setAvatar(body.avatar);
        setMaxRecordingTime(body.max_recording_time);
      }
    }
    fetchData();
  }, [setRequireName, setRequireEmail, setTitle, setDescription]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("requireEmail", requireEmail);
    formData.append("requireName", requireName);
    formData.append("username", username);
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("avatar", photo);
    formData.append("backgroundImage", backgroundImage);
    formData.append("maxRecordingTime", maxRecordingTime);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );

    if (response.status === 200) {
      const body = await response.json();
      setAuthContextEmail(body.email);
      setAuthContextFullName(body.full_name);
      setAuthContextUsername(body.username);
      setAuthContextAvatar(body.avatar);
      setUsernameErr(false);
      setEmailErr(false);
      setSaved(true);
      setTimeout(() => {
        setSaved(false);
        // props.setCurrent("Inbox");
        // navigator("/inbox");        
      }, 500);

      await fetch(`${process.env.REACT_APP_BACKEND_URL}/analytics/lt`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          event: "Settings Updated",
          properties: {
            username: body.username,
            userEmail: body.email,
            fullName: body.full_name,
          },
        }),
      });
    } else {
      const err = await response.json();
      console.log(err);
      if (err.err === "username taken") {
        console.log(err);
        setUsernameErr(true);
      } else if (err.err === "email taken") {
        setEmailErr(true);
      }
    }
  };

  const handleRemove = async (event) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/settings/remove_background`,
      {
        method: "POST",
        credentials: "include",
      }
    );
    if (response.status === 200) {
      event.target.value = null;
      backgroundImageInputRef.current.value = null;
      setBackgroundImage(null);
      setRemoved(true);
      setTimeout(() => {
        setRemoved(false);
      }, 2000);
    }
  };

  const handleMaxRecordingTimeClick = (event) => {
    const value = event.target.value;
    if (value === "null") {
      setMaxRecordingTime(null);
    } else {
      setMaxRecordingTime(parseInt(value));
    }
  };

  return (
    <form
      className="space-y-8 divide-y divide-gray-200 pt-10 text-left"
      onSubmit={handleSubmit}
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 antialiased">
        <div>
          <div>
            {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be displayed publicly so be careful what you share.
              </p> */}
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Bolo Link
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="max-w-lg flex rounded-md shadow-sm">
                <span className="inline-flex items-center pl-3 pr-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-800 font-medium sm:text-sm">
                  bolo.fm/
                </span>
                <input
                  type="text"
                  name="username"
                  id="username"
                  autoComplete="username"
                  className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 pl-1"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <p className="mt-2 text-sm text-gray-700">
                Careful: Changing this will change your recording page link!
              </p>
              <div
                className={classNames(
                  !usernameErr ? "hidden" : "",
                  " text-sm text-red-500"
                )}
              >
                <p>Username already taken.</p>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  id="description"
                  name="description"
                  rows={3}
                  className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <p className="mt-2 text-sm text-gray-700">
                  Write a few sentences for your fans. This message will appear
                  on the recording page.
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Profile Photo
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-center">
                  {/* <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                    {avatar ? (
                      <img src={avatar} alt="Avatar" />
                    ) : (
                      <Avatar name={authContextFullName || ""} />
                    )}
                  </span> */}
                  <input
                    type="file"
                    accept="image/*"
                    className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onChange={(e) => {
                      setPhoto(e.target.files[0]);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Background Image
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-center">
                  <input
                    type="file"
                    accept="image/*"
                    className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onChange={(e) => {
                      setBackgroundImage(e.target.files[0]);
                    }}
                    ref={backgroundImageInputRef}
                  />
                  <span>
                    <button
                      className="ml-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-bolo-purple hover:bg-bolo-purple-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bg-bolo-purple-dark"
                      onClick={handleRemove}
                    >
                      Remove
                    </button>
                    <span className="pt-2 px-2" hidden={!removed}>
                      removed!
                    </span>
                  </span>
                </div>
                <p className="mt-2 text-sm text-gray-700">
                  This image will appear as the background of your recorder
                  page.
                </p>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Max Recording Length
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-center">
                  <span className="relative z-0 inline-flex shadow-sm rounded-md">
                    <button
                      type="button"
                      value="15"
                      onClick={handleMaxRecordingTimeClick}
                      className={classNames(
                        maxRecordingTime === 15
                          ? "bg-bolo-purple-lightest text-white"
                          : "bg-white text-gray-700",
                        "relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium"
                      )}
                    >
                      15 sec
                    </button>
                    <button
                      type="button"
                      value="60"
                      onClick={handleMaxRecordingTimeClick}
                      className={classNames(
                        maxRecordingTime === 60
                          ? "bg-bolo-purple-lightest text-white"
                          : "bg-white text-gray-700",
                        "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium"
                      )}
                    >
                      60 sec
                    </button>
                    <button
                      type="button"
                      value="180"
                      onClick={handleMaxRecordingTimeClick}
                      className={classNames(
                        maxRecordingTime === 180
                          ? "bg-bolo-purple-lightest text-white"
                          : "bg-white text-gray-700",
                        "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium"
                      )}
                    >
                      3 min
                    </button>
                    <button
                      type="button"
                      value="null"
                      onClick={handleMaxRecordingTimeClick}
                      className={classNames(
                        maxRecordingTime === null
                          ? "bg-bolo-purple-lightest text-white"
                          : "bg-white text-gray-700",
                        "relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium"
                      )}
                    >
                      No limit
                    </button>
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="photo"
                className="block text-sm font-medium text-gray-700"
              >
                Personal Page background color
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="flex items-center">
                  <input
                    type="color"
                    className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  />
                </div>
              </div>
            </div> */}

            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Profile photo
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleProfilePhotoInput}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-500">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="divide-y divide-gray-200 pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          {/* <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Notifications</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                We'll always let you know about important changes, but you pick what else you want to hear about.
              </p>
            </div> */}
          <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200">
            <div className="pt-6 sm:pt-5">
              <div role="group" aria-labelledby="label-email">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                  {/* <div>
                      <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                        By Email
                      </div>
                    </div> */}
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="require-name"
                            name="require-name"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            checked={Number(requireName)}
                            onChange={(e) => setRequireName(e.target.checked)}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="require-name"
                            className="font-medium text-gray-900"
                          >
                            Require Name
                          </label>
                          <p className="text-gray-700">
                            Require Name when a fan leaves a message
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 sm:mt-0 sm:col-span-2">
                    <div className="max-w-lg space-y-4">
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="require-email"
                            name="require-email"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            checked={Number(requireEmail)}
                            onChange={(e) => setRequireEmail(e.target.checked)}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="require-email"
                            className="font-medium text-gray-900"
                          >
                            Require Email
                          </label>
                          <p className="text-gray-700">
                            Require Email when a fan leaves a message
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pt-6 sm:pt-5">
                <div role="group" aria-labelledby="label-notifications">
                  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                    <div>
                      <div
                        className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                        id="label-notifications"
                      >
                        Push Notifications
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <div className="max-w-lg">
                        <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
                        <div className="mt-4 space-y-4">
                          <div className="flex items-center">
                            <input
                              id="push-everything"
                              name="push-notifications"
                              type="radio"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700">
                              Everything
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="push-email"
                              name="push-notifications"
                              type="radio"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push-email" className="ml-3 block text-sm font-medium text-gray-700">
                              Same as email
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="push-nothing"
                              name="push-notifications"
                              type="radio"
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
                              No push notifications
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Personal Information
                </h3>
                {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Use a permanent address where you can receive mail.</p> */}
              </div>
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="full-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Full name
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      type="text"
                      name="full-name"
                      id="full-name"
                      autoComplete="full-name"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Email address
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div
                      className={classNames(
                        !emailErr ? "hidden" : "",
                        " text-sm text-red-500"
                      )}
                    >
                      <p>Email already taken.</p>
                    </div>
                  </div>
                </div>

                {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="country" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Country / Region
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country"
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  >
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                  </select>
                </div>
              </div>
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="street-address" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Street address
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="street-address"
                    id="street-address"
                    autoComplete="street-address"
                    className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  City
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="state" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  State / Province
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="state"
                    id="state"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
  
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label htmlFor="zip" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  ZIP / Postal
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="zip"
                    id="zip"
                    autoComplete="postal-code"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-start">
          {/* <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button> */}
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-bolo-purple hover:bg-bolo-purple-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bg-bolo-purple-dark"
          >
            Save
          </button>
          <div className="pt-2 px-2" hidden={!saved}>
            saved!
          </div>
        </div>
      </div>
    </form>
  );
}
