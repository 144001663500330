import timeDifference from "../utils/time_diff";

export default function AudioClipsList(props) {
  async function onPlay(uuid) {
    await fetch(`${process.env.REACT_APP_BACKEND_URL}/analytics/lt`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        event: "Message Played",
        properties: {
          message_uuid: uuid,
        },
      }),
    });
  }

  return (
    <div>
      <div className="flex justify-center items-center flex-col lg:px-10 py-10">
        <div className="w-full">
          <p className="text-black text-l lg:text-xl font-bold text-left m-1 -ml-2">
            Share your Bolo page with your supporters <br />
            <p className="text-gray-500 font-normal py-2">
              to receive more messages
            </p>
          </p>
          {!props.audioClips.length && (
            <div className="mt-20">
              <p className="text-black text-xl text-center m-1">
                The messages you receive will appear here.
              </p>
              <p className="text-black text-xl text-center m-1">
                Try sending yourself a{" "}
                <a
                  href={`https://bolo.fm/${props.username}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-indigo-600 hover:text-indigo-500"
                >
                  test message
                </a>
                !
              </p>
            </div>
          )}
        </div>
        <ul className="divide-y lg:w-3/5 md:w-4/5 sm:w-4/6">
          {props.audioClips.length > 0 &&
            props.audioClips.map((clip) => (
              <li key={clip.clip_id} className="py-4  m-4 rounded-lg shadow">
                <div className="flex space-x-3">
                  <div className="flex-1 space-y-1 items-center">
                    <div className="flex flex-col text-left w-11/12 ">
                      <div className="px-2 pb-1 font-normal font-semibold">
                        {clip.poster_name}
                        {clip.poster_email ? (
                          <span className="px-1">({clip.poster_email})</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="px-2 pb-1 font-normal text-black text-opacity-100">
                        {timeDifference(new Date(), new Date(clip.created_at))}
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-between">
                      <audio
                        src={clip.clip_uri}
                        controls
                        autoPlay={false}
                        className="w-11/12"
                        onPlay={() => onPlay(clip.clip_id)}
                      />
                    </div>
                    <div className="text-right px-10 pt-2 font-bold text-red-600">
                      <button
                        onClick={() =>
                          props.handleAudioClipDelete(clip.clip_id)
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
