import { useState, createContext, useEffect } from "react";

const AuthContext = createContext();

export function AuthProvider(props) {
  const [authContextFullName, setAuthContextFullName] = useState("");
  const [authContextEmail, setAuthContextEmail] = useState("");
  const [authContextUsername, setAuthContextUsername] = useState("");
  const [authContextAvatar, setAuthContextAvatar] = useState("");

  useEffect(() => {
    function getItemsFromLocalStorage() {
      const email = localStorage.getItem("email");
      const full_name = localStorage.getItem("full_name");
      const podLink = localStorage.getItem("unique_link");
      const avatarLink = localStorage.getItem("logged_in_user_avatar");

      setAuthContextFullName(full_name);
      setAuthContextEmail(email);
      setAuthContextUsername(podLink);
      setAuthContextAvatar(avatarLink);
    }
    getItemsFromLocalStorage();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authContextEmail,
        setAuthContextEmail,
        authContextFullName,
        setAuthContextFullName,
        authContextUsername,
        setAuthContextUsername,
        authContextAvatar,
        setAuthContextAvatar,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
