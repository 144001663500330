import useSWR from "swr";

const fetcher = async (url) => {
  const res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  });
  return res.json();
};

export function useRecorder(podName) {
  const params = { pod_name: podName };
  const queryString = new URLSearchParams(params).toString();
  const { data, error } = useSWR(`${process.env.REACT_APP_BACKEND_URL}/verify-pod?${queryString}`, fetcher);
  return { pod: data, error, loading: !error && !data };
}
