import { useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { useNavigate } from "react-router";
import {
  PaperAirplaneIcon,
  MicrophoneIcon,
  StopIcon,
} from "@heroicons/react/outline";
import LogoName from "../assets/logoNameWBorder";
import Avatar from "../assets/avatar";
import { useRecorder } from "../hooks/use_recorder";
import { useInterval } from "../hooks/use_interval";

async function track(username, action, pod) {
  await fetch(`${process.env.REACT_APP_BACKEND_URL}/analytics/ult`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      event: action,
      properties: {
        username,
        pod,
      },
    }),
  });
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Button(props) {
  return (
    <button
      type="button"
      className="inline-flex items-center justify-center w-full md:w-3/4 py-3 border-2 border-transparent shadow-sm text text-base font-medium rounded-md text-white bg-bolo-purple hover:bg-bolo-purple-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bolo-purple-dark"
      onClick={props.handleClick}
    >
      {props.text}
      {props.icon && <span className="px-1">{props.icon}</span>}
    </button>
  );
}

function FormSubmitButton(props) {
  return (
    <button
      className={classNames(
        props.uploading ? "animate-pulse" : "",
        "inline-flex items-center justify-center w-full py-3 border-2 border-transparent shadow-sm text text-base font-medium rounded-md text-white bg-bolo-purple hover:bg-bolo-purple-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bolo-purple-dark"
      )}
      type="submit"
    >
      {props.text}
      {props.icon && <span className="px-1">{props.icon}</span>}
    </button>
  );
}

function InvertedButton(props) {
  return (
    <button
      type="button"
      className="mt-2 bg-white w-full md:w-3/4 py-3 border-2 border-bolo-purple rounded-md shadow-sm text text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bolo-purple-dark"
      onClick={props.handleClick}
    >
      {props.text}
      {props.icon && <span className="px-1">{props.icon}</span>}
    </button>
  );
}

function FormInvertedButton(props) {
  return (
    <button
      type="button"
      className="mt-2 bg-white w-full py-3 border-2 border-bolo-purple rounded-md shadow-sm text text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bolo-purple-dark"
      onClick={props.handleClick}
    >
      {props.text}
      {props.icon && <span className="px-1">{props.icon}</span>}
    </button>
  );
}

function RecordButton(props) {
  return (
    <div>
      <div className="py-5 p-6 lg:max-w-sm max-w-sm text-left font-medium px-5 whitespace-pre-wrap">
        {props.pod.description}
      </div>
      <div className="py-5 md:px-0 px-5">
        <Button
          text="Record a message"
          icon={<MicrophoneIcon className="h-6 w-6" />}
          handleClick={props.handleClick}
        />
      </div>
    </div>
  );
}

function ProvidePermissionButton(props) {
  return (
    <>
      <div>
        <div className="py-5 p-6 lg:max-w-sm max-w-sm text-left font-medium px-5 whitespace-pre-wrap">
          {props.pod.description}
        </div>
        <div class="lg:max-w-sm max-w-sm font-medium px-5 text-center bg-red-500">
          <p className="text-yellow-100 font-bold">
            You need to allow microphone access to record your message.
          </p>
        </div>
        <div className="py-5 md:px-0 px-5">
          <Button
            text="I have provided permission"
            handleClick={props.handleClick}
          />
        </div>
      </div>
    </>
  );
}

function StopRecordingButton(props) {
  const maxRecordingTime = props.pod.max_recording_time;
  const [recordingTime, setRecordingTime] = useState(maxRecordingTime);

  useInterval(
    () => {
      setRecordingTime(recordingTime - 1);
    },
    recordingTime > 0 ? 1000 : null
  );

  useEffect(() => {
    if (recordingTime === 0) {
      props.handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordingTime]);

  return (
    <div>
      <div className="py-5 p-6 lg:max-w-sm max-w-sm text-left font-medium px-5 whitespace-pre-wrap">
        {props.pod.description}
      </div>
      <div className="py-5 md:px-0 px-5">
        <Button
          className="animate-pulse"
          text={<span className="animate-pulse">Stop Recording</span>}
          icon={
            <span className="flex flex-row animate-pulse">
              {!!maxRecordingTime && (
                <span className="pl-1 pr-1">{recordingTime}s</span>
              )}
              <StopIcon className="h-6 w-6 pl-1" />
            </span>
          }
          handleClick={props.handleClick}
        />
      </div>
    </div>
  );
}

function RecordAnotherButton(props) {
  const nav = useNavigate();
  return (
    <div className="py-5">
      <div className="p-8 sm:p-6 lg:max-w-sm sm:max-w-sm text-left md:ml-6">
        <p className="mb-5">Your message was sent! That was easy :)</p>
        <p className="mb-5">
          Want to get your own Bolo so others can leave you messages? Claim your
          link below!
        </p>
        <p className="mb-5">
          You can also send @{props.pod.username} another message if you’d like!
        </p>
      </div>
      <div className="flex flex-col items-center px-5">
        <Button
          text="Record another message"
          icon={<MicrophoneIcon className="h-6 w-6" />}
          handleClick={props.handleClick}
        />
        <InvertedButton
          text="Claim your Bolo link"
          handleClick={() => {
            track(props.pod.username, "Clicked Claim Bolo Link");
            nav("/signup");
          }}
        />
      </div>
    </div>
  );
}

function RecordingCompleteForm(props) {
  function handleEmailChange(e) {
    props.setEmail(e.target.value);
  }

  function handleNameChange(e) {
    props.setName(e.target.value);
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="italic text-gray-500 py-5 md:px-0 px-5">
      Review your message for @{props.pod.username}
      <div className="flex flex-col justify-center">
        <form
          className="flex flex-col justify-center md:mx-12"
          onSubmit={props.handleUploadClick}
        >
          <div className="mt-2 w-full">
            <audio
              className="w-full"
              src={props.mediaBlobUrl}
              controls
              autoPlay
            />
          </div>
          <div className={classNames(props.requireName ? "" : "", "py-2")}>
            <label
              htmlFor="name"
              className="block text-sm text-left font-medium text-gray-700"
            >
              Your Name
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="name"
                id="name"
                className="focus:ring-bolo-purple focus:border-bolo-purple block w-full border border-gray-400 rounded-md pl-4"
                placeholder="Your name"
                required={props.pod.poster_name_required}
                value={props.name}
                onChange={handleNameChange}
              />
            </div>
          </div>
          <div className="">
            <label
              htmlFor="email"
              className="block text-sm text-left font-medium text-gray-700"
            >
              Your Email
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="email"
                name="email"
                id="email"
                required={props.pod.poster_email_required}
                className="focus:ring-bolo-purple focus:border-bolo-purple block w-full border border-gray-400 rounded-md pl-4"
                placeholder="you@example.com"
                value={props.email}
                onChange={handleEmailChange}
              />
            </div>
          </div>
          <div className="pt-5">
            <FormSubmitButton
              text={props.uploading ? "Sending Message..." : "Send Message"}
              icon={<PaperAirplaneIcon className="h-6 w-6" />}
              disabled={props.uploading}
              uploading={props.uploading}
            />
            <FormInvertedButton
              text="Start Over"
              handleClick={props.handleResetClick}
              disabled={props.uploading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default function RecorderWrapper(props) {
  const navigate = useNavigate();
  const podName = window.location.pathname.substring(1);
  let { pod, error, loading } = useRecorder(podName);
  if (loading) return <Placeholder />;
  if (error) {
    navigate("/404");
  }
  return <Recorder {...props} pod={pod} />;
}

function Recorder(props) {
  // const [recordedMedia, setRecordedMedia] = useState();
  const [currentState, setCurrentState] = useState("has_not_started_recording");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [uploading, setUploading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [pod, setPod] = useState({});

  const pod = props.pod;
  const { error, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });

  useEffect(() => {
    if (error === "permission_denied") {
      track(pod.username, "Mic Permission Denied");
      setCurrentState("permission_denied");
      handleStateChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  async function handleStateChange(event) {
    switch (currentState) {
      case "has_not_started_recording":
        // this await is needed
        await startRecording();
        // the problem is
        // that the error state is set before the state change
        // for currentState is set. This causes the double need to provide access
        if (error !== "permission_denied") {
          setCurrentState("recording");
        } else {
          stopRecording();
          setCurrentState("permission_denied");
        }
        track(pod.username, "Started Recording");
        break;
      case "recording":
        stopRecording();
        track(pod.username, "Stopped Recording");
        setCurrentState("recording_complete");
        break;
      case "recording_complete":
        track(pod.username, "Reset Recording");
        setCurrentState("has_not_started_recording");
        break;
      case "permission_denied":
        track(pod.username, "Mic Permission Denied");
        if (event) {
          setCurrentState("has_not_started_recording");
        }
        break;
      default:
        track(pod.username, "Unknown State Triggered");
        setCurrentState("has_not_started_recording");
        break;
    }
  }

  async function uploadHandler(e) {
    if (uploading) return;

    setUploading(true);
    e.preventDefault();
    const presignedUrl = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/audio-clips`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          pod_name: window.location.pathname.substring(1),
          poster_name: name,
          poster_email: email,
        }),
      }
    );
    const presignedUri = await presignedUrl.json();

    const audioBlob = await fetch(mediaBlobUrl);
    const audioBodyReader = audioBlob.body.getReader();
    const audioBody = await audioBodyReader.read();

    const res = await fetch(presignedUri.upload_uri, {
      method: "PUT",
      body: audioBody.value,
      headers: {
        "Content-Type": "audio/wav",
      },
    });
    track(pod.username, "Uploaded Recording");

    if (res.status !== 200) {
      console.log(res.body);
    }
    setUploading(false);
    setCurrentState("thanks");
  }

  function renderState(param) {
    switch (param) {
      case "has_not_started_recording":
        return <RecordButton handleClick={handleStateChange} pod={pod} />;
      case "permission_denied":
        return (
          <ProvidePermissionButton handleClick={handleStateChange} pod={pod} />
        );
      case "recording":
        return (
          <StopRecordingButton handleClick={handleStateChange} pod={pod} />
        );
      case "recording_complete":
        return (
          <RecordingCompleteForm
            handleResetClick={handleStateChange}
            handleUploadClick={uploadHandler}
            mediaBlobUrl={mediaBlobUrl}
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            pod={pod}
            uploading={uploading}
          />
        );
      case "thanks":
        return (
          <RecordAnotherButton handleClick={handleStateChange} pod={pod} />
        );
      default:
        return;
    }
  }

  if (pod.background_image) {
    return (
      <CustomBackgroundRecorder
        pod={pod}
        renderState={renderState}
        currentState={currentState}
      />
    );
  } else {
    return (
      <BoloRecorder
        pod={pod}
        renderState={renderState}
        currentState={currentState}
      />
    );
  }
}

function BoloRecorder({ pod, renderState, currentState }) {
  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center bg-bolo-purple min-h-screen relative">
      <a href="/" className="mt-5 mb-10">
        <LogoName fill="#FFFFFF" className="h-20 w-48" />
      </a>
      <RecorderPanel
        pod={pod}
        renderState={renderState}
        currentState={currentState}
      />
      <Footer podName={pod.name} />
    </div>
  );
}

function CustomBackgroundRecorder({ pod, renderState, currentState }) {
  return (
    <div
      className="mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center bg-cover bg-center min-h-screen relative"
      style={{ backgroundImage: `url(${pod.background_image})` }}
    >
      <a href="/" className="mt-5 mb-10">
        <LogoName fill="#96FCFF" className="h-20 w-48" />
      </a>
      <RecorderPanel
        pod={pod}
        renderState={renderState}
        currentState={currentState}
      />
      <Footer podName={pod.name} />
    </div>
  );
}

function Placeholder() {
  const podName = window.location.pathname.substring(1);
  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center bg-bolo-purple min-h-screen relative">
      <a href="/" className="mt-5 mb-10">
        <LogoName fill="#FFFFFF" className="h-20 w-48" />
      </a>
      <Footer podName={podName} />
    </div>
  );
}

function RecorderPanel({ pod, renderState, currentState }) {
  return (
    <div className="md:max-w-3xl md:mx-auto w-80 md:w-96 flex flex-col justify-center relative mtp-10 mbp-10">
      <div className="inline-block h-28 w-28 rounded-full ml-6 absolute -top-16 border-2 border-bolo-purple overflow-hidden">
        {pod.avatar ? (
          <img src={pod.avatar} alt="avatar" />
        ) : (
          <Avatar name={pod.name} />
        )}
      </div>
      <div className="bg-white overflow-hidden shadow rounded-md pt-10 flex flex-col border-2 border-bolo-purple bg-bolo-ghost-white">
        <div className="text-left ml-6 pt-4 font-bold">{pod.name}</div>
        <div className="text-gray-500 font-medium text-left ml-6 italic">
          @{pod.username}
        </div>
        {/* py-5 sm:p-6 md:px-20 lg:max-w-sm sm:max-w-sm text-left ml-6 font-medium */}
        {renderState(currentState)}
      </div>
    </div>
  );
}

function Footer({ podName, textColor }) {
  textColor = textColor || "text-gray-300";
  return (
    <div className="footer bottom-2 text-gray-300 italic opacity-80 font-medium absolute pt-2">
      <p>
        This page was created by {podName} in{" "}
        <a href="/">Bolo</a>.
      </p>
      <p>© Bolo.fm 2021</p>
    </div>
  );
}
